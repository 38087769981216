import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";


const _ = require("lodash");

export default class PostTemplate extends React.Component {
    render() {
        const { data, pageContext } = this.props;
        const { slug } = pageContext;
        const postNode = data.airtable;
        const post = postNode.data;



        const chartData = post.data;

        const chart1marray = JSON.parse(chartData);

        const chart1mdate = chart1marray.map(data => '"'+data.date+'"');
        const chart1mclose = chart1marray.map(data => data.close);
        const chart1mvolume = chart1marray.map(data => data.volume);
        
        const quickcharturl = 
        "https://chart.shareprice.ie/chart?encoding=url&format=png&c={type:'line', options: { scales: { xAxes: [{ ticks: { fontSize: 8 }, gridLines: { drawOnChartArea: false } }], yAxes: [{ ticks: { fontSize: 8 }, gridLines: { drawOnChartArea: false } }] }, legend: { labels: {fontColor: 'gray',fontSize: 8} } }, data:{labels:["
        +chart1mdate+"], datasets:[{label:'Closing price',data:["+chart1mclose+"], fill:false,borderColor:'rgba(56,123,45,0.2)',borderWidth: 1, pointRadius: 0}]}}";


        if (!post.id) {
            post.id = slug;
        }

        const oldNumber = post.o;
        const newNumber = post.c;
        const changeValue = newNumber - oldNumber;
        const changePercentage = (changeValue / oldNumber) * 100;
        const changePercentageDecimal = changePercentage.toFixed(2);

        return (
            <Layout>
                <Helmet>
                    <title>{`${post.name} Share Price on ${post.exchangeName}`}</title>
                    <meta name="description" content={`Get the latest ${post.currency} share price for ${post.name} listed on the ${post.exchangeName}. Includes day high, low, open, close, and fundamentals.`} />
                    <link rel="canonical" href={config.siteUrl + '/markets/' + _.kebabCase(post.exchangeName) + '/' + _.kebabCase(post.name) + '/'} />
                </Helmet>
                <div>

                    <div className="pt-6 bg-white overflow-hidden mb-8">
                        <div className="relative mx-auto pl-4 pr-4">
                            <div className="relative">

                                <nav class="flex items-center text-sm leading-5 font-medium pb-4">
                                    <a href="/" class="text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out">Share Prices</a>
                                    <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-500 hidden md:block" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
                                    </svg>
                                    <a href={'/markets/' + _.kebabCase(post.exchangeName) + '/'} class="text-gray-400 hover:text-gray-200 transition duration-150 ease-in-out hidden md:block">{post.exchangeTitle}</a>
                                    <svg class="flex-shrink-0 mx-2 h-5 w-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
                                    </svg>
                                    <div class="text-gray-400">{post.name}</div>
                                </nav>


                                <div className="pb-8 bg-white">
                                    <div className="relative">
                                        <div className="relative mx-auto">
                                            <div className="mx-auto overflow-hidden lg:flex">
                                                <div className="bg-white lg:flex-shrink-1">
                                                    <h1 className="text-3xl leading-8 font-extrabold text-gray-900 sm:text-4xl sm:leading-9">
                                                        {post.name} Share Price
                                                    </h1>

                                                    <p className="mt-4">
                                                        <button className="font-semibold text-gray-500 text-sm" onClick={() => window.location.reload(false)}>{post.t} (click here for latest price)</button>
                                                    </p>
                                                   
                                                    <p className="mt-4 text-base leading-6 text-gray-500">
                                                        {post.name} competes in the {post.sector} sector and {post.industry} industry. It is headquartered in {post.city}, {post.country} and is traded on the {post.exchangeName}, through the symbol {post.symbol}.
                                                    </p>


                                                </div>
                                                <div className="text-center py-4 px-4 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:px-12">
                                                    <div className="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-900">
                            <span>

                                {post.region === 'GB' &&<span>GBX</span>}
                                {new Intl.NumberFormat("en-GB", {
                                    style: "currency",
                                    currency: post.currency
                                }).format(post.c)}

                            </span>
                                                        <span className="ml-3 text-xl leading-7 font-medium text-gray-500">
                                {changePercentageDecimal < 0 ?
                                    <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-red-100 text-red-800 md:mt-2 lg:mt-0">
                                        <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clip-rule="evenodd"/>
                                        </svg>
                                        {changePercentageDecimal}%
                                    </div>
                                    :
                                    <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                                        <svg class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" fill="currentColor" viewBox="0 0 20 20">
                                            <path fill-rule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
                                        </svg>
                                        {changePercentageDecimal}%
                                    </div>
                                }
                            </span>
                                                    </div>
                                                    <div className="mt-4 text-sm font-semibold leading-5 text-gray-500">
                                                        Previous Close:
                                                    </div>
                                                    <div className="mt-2 text-md leading-5 text-gray-500">
                                                        {post.region === 'GB' &&<span>GBX</span>}
                                                        {new Intl.NumberFormat("en-GB", {
                                                            style: "currency",
                                                            currency: post.currency
                                                        }).format(post.previousClose)} <span style={{color: post.previousChangePercent < 0 ? "#F05252" : "#2F9E6D"}}>{post.previousChangePercent}%</span> ({post.previousDate})
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="pb-8">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Latest Share Trading Activity for {post.name}
                                    </h3>
                                    <div className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-3">
                                        <div>
                                            <div className="px-4 py-5 sm:p-6">
                                                <dl>
                                                    <dt className="text-base leading-6 font-normal text-gray-900">
                                                        Day Open:
                                                    </dt>
                                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                                        <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-900">
                                                            {post.region === 'GB' &&<span>GBX</span>}
                                                            {new Intl.NumberFormat("en-GB", {
                                                                style: "currency",
                                                                currency: post.currency
                                                            }).format(post.o)}

                                                            <span className="ml-2 text-sm leading-5 font-medium text-gray-500">
                                                            
                                (previous open {post.region === 'GB' &&<span>GBX</span>}{new Intl.NumberFormat("en-GB", {
                                                                style: "currency",
                                                                currency: post.currency
                                                            }).format(post.previousOpen)})
                            </span>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <div className="border-t border-gray-200 md:border-0 md:border-l">
                                            <div className="px-4 py-5 sm:p-6">
                                                <dl>
                                                    <dt className="text-base leading-6 font-normal text-gray-900">
                                                        Day High:
                                                    </dt>
                                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                                        <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-900">
                                                            {post.region === 'GB' &&<span>GBX</span>}
                                                            {new Intl.NumberFormat("en-GB", {
                                                                style: "currency",
                                                                currency: post.currency
                                                            }).format(post.h)}

                                                            <span className="ml-2 text-sm leading-5 font-medium text-gray-500">
                                (previous high {post.region === 'GB' &&<span>GBX</span>}{new Intl.NumberFormat("en-GB", {
                                                                style: "currency",
                                                                currency: post.currency
                                                            }).format(post.previousHigh)})
                            </span>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                        <div className="border-t border-gray-200 md:border-0 md:border-l">
                                            <div className="px-4 py-5 sm:p-6">
                                                <dl>
                                                    <dt className="text-base leading-6 font-normal text-gray-900">
                                                        Day Low:
                                                    </dt>
                                                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                                                        <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-900">
                                                            {post.region === 'GB' &&<span>GBX</span>}
                                                            {new Intl.NumberFormat("en-GB", {
                                                                style: "currency",
                                                                currency: post.currency
                                                            }).format(post.l)}

                                                            <span className="ml-2 text-sm leading-5 font-medium text-gray-500">
                                (previous low {post.region === 'GB' &&<span>GBX</span>}{new Intl.NumberFormat("en-GB", {
                                                                style: "currency",
                                                                currency: post.currency
                                                            }).format(post.previousLow)})
                            </span>
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div className="pb-8">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        About {post.name}
                                    </h3>
                                    <div className="mt-5 bg-white shadow overflow-hidden rounded-lg">
                                        <div className="px-4 py-5 sm:p-0">
                                            <dl>
                                                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                                                    <dt className="text-sm leading-5 font-medium text-gray-500">
                                                        Company Description
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                                        {post.description}
                                                    </dd>
                                                </div>
                                                <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                                                    <dt className="text-sm leading-5 font-medium text-gray-500">
                                                        Chief Executive Officer (CEO)
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                                        {post.ceo}
                                                    </dd>
                                                </div>
                                                <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                                                    <dt className="text-sm leading-5 font-medium text-gray-500">
                                                        Website Address
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                                        <a href={post.website} target="_blank">{post.website}</a>
                                                    </dd>
                                                </div>
                                                <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                                                    <dt className="text-sm leading-5 font-medium text-gray-500">
                                                        Postal Address
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                                        {post.address} {post.address2} {post.state} {post.city} {post.country}
                                                    </dd>
                                                </div>
                                                <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                                                    <dt className="text-sm leading-5 font-medium text-gray-500">
                                                        Phone Number
                                                    </dt>
                                                    <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                                        {post.phone}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>

                                </div>

                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    {post.name} Historic Prices and Volume
                                </h3>

                                <div className="bg-white">

                                    <div className="max-w-xl mx-auto sm:px-4 lg:max-w-screen-xl sm:max-w-screen-xl">
                                        <div className="lg:grid lg:grid-cols-2 lg:gap-20">
                                            <div className="mt-10 lg:mt-0">
                                                <div className="mt-5">

                                                    <div className="flex flex-col">
                                                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                            <div className="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-2">
                                                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                                    <table className="min-w-full divide-y divide-gray-200">
                                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                Currency
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                                                                {post.currency}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                Issue Country
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                                                                {post.region}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                52 Week Range
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">

                                                                                {new Intl.NumberFormat("en-GB", {
                                                                                    style: "currency",
                                                                                    currency: post.currency
                                                                                }).format(post.week52low)}&nbsp;-&nbsp;

                                                                                {new Intl.NumberFormat("en-GB", {
                                                                                    style: "currency",
                                                                                    currency: post.currency
                                                                                }).format(post.week52high)}

                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                52 Week Change
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <span style={{color: post.week52change < 0 ? "#F05252" : "#2F9E6D"}}>
                                                {new Intl.NumberFormat("en-GB", {
                                                    style: "currency",
                                                    currency: post.currency
                                                }).format(post.week52change)}
                                            </span>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                50 Day Moving Average
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                                                                {new Intl.NumberFormat("en-GB", {
                                                                                    style: "currency",
                                                                                    currency: post.currency
                                                                                }).format(post.day50MovingAvg)}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                200 Day Moving Average
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                                                                {new Intl.NumberFormat("en-GB", {
                                                                                    style: "currency",
                                                                                    currency: post.currency
                                                                                }).format(post.day200MovingAvg)}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                Average 10 Day Volume
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                                                                {post.avg10Volume}
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                Average 30 Day Volume
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                                                                {post.avg30Volume}
                                                                            </td>
                                                                        </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="mt-10 lg:mt-0">
                                                <div className="mt-5">


                                                    <div className="flex flex-col">
                                                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                            <div className="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-2">
                                                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                                                    <table className="min-w-full divide-y divide-gray-200">
                                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                5 Day % Change
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <span style={{color: post.day5ChangePercent < 0 ? "#F05252" : "#2F9E6D"}}>
                                            {new Intl.NumberFormat("en-GB", {
                                                style: "percent",
                                            }).format(post.day5ChangePercent)}    
                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                30 Day % Change
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <span style={{color: post.day30ChangePercent < 0 ? "#F05252" : "#2F9E6D"}}>
                                                
                                            {new Intl.NumberFormat("en-GB", {
                                                style: "percent",
                                            }).format(post.day30ChangePercent)} 
                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                3 Month % Change
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <span style={{color: post.month3ChangePercent < 0 ? "#F05252" : "#2F9E6D"}}>
                                            {new Intl.NumberFormat("en-GB", {
                                                style: "percent",
                                            }).format(post.month3ChangePercent)}     
                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                6 Month % Change
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <span style={{color: post.month6ChangePercent < 0 ? "#F05252" : "#2F9E6D"}}>
                                            {new Intl.NumberFormat("en-GB", {
                                                style: "percent",
                                            }).format(post.month6ChangePercent)}    
                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                1 Year % Change
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <span style={{color: post.year1ChangePercent < 0 ? "#F05252" : "#2F9E6D"}}>
                                            {new Intl.NumberFormat("en-GB", {
                                                style: "percent",
                                            }).format(post.year1ChangePercent)}  
                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                2 Year % Change
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <span style={{color: post.year2ChangePercent < 0 ? "#F05252" : "#2F9E6D"}}>
                                            {new Intl.NumberFormat("en-GB", {
                                                style: "percent",
                                            }).format(post.year2ChangePercent)}  
                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                5 Year % Change
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <span style={{color: post.year5ChangePercent < 0 ? "#F05252" : "#2F9E6D"}}>
                                            {new Intl.NumberFormat("en-GB", {
                                                style: "percent",
                                            }).format(post.year5ChangePercent)}  
                                            </span>

                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                                                                                Year To Date % Change
                                                                            </td>
                                                                            <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                                            <span style={{color: post.ytdChangePercent < 0 ? "#F05252" : "#2F9E6D"}}>
                                            {new Intl.NumberFormat("en-GB", {
                                                style: "percent",
                                            }).format(post.ytdChangePercent)} 
                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>

                </div>

            </Layout>
        );
    }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    airtable(data: {symbol: {eq: $slug}}) {
      data {
                        symbol
                        exchange
                        name
                        region
                        currency
                        exchangeName
                        industry
                        website
                        description
                        ceo
                        sector
                        address
                        city
                        zip
                        country
                        phone
                        previousDate(formatString: "MMMM Do, YYYY")
                        previousOpen
                        previousClose
                        previousHigh
                        previousLow
                        previousVolume
                        previousChange
                        previousChangePercent
                        previousChangeOverTime
                        address2
                        state
                        c
                        h
                        l
                        o
                        pc
                        t(formatString: "MMMM Do, YYYY hh:mma")
                        exchangeTitle
                        week52change
                        week52high
                        week52low
                        day200MovingAvg
                        day50MovingAvg
                        avg10Volume
                        avg30Volume
                        month1ChangePercent
                        month3ChangePercent
                        month6ChangePercent
                        ytdChangePercent
                        year1ChangePercent
                        year2ChangePercent
                        year5ChangePercent
                        day5ChangePercent
                        day30ChangePercent
                        maxChangePercent
                        data
      }
    }
  }
`;
